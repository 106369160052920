
export const Styles = {
    pagination:{
        display:"flex",
        width:"100%",
        paddingLeft: 0,
        margin: "20px 0",
        borderRadius: "4px",
        alignItems:"center"
    },
    pageCountLi:{
        display:"flex",
        alignItems: "center"
    },
    pages:{
        root:{
            minWidth:"24px",
            height: "24px",
            border: "none",
            marginRight: "2px",
            marginLeft: "2px",
            padding: 0,
            ".ms-Button-label":{
                fontWeight:"normal"
            }
        }
    },
    active:{
        root: {
            background:"#F78A05",
            color: "#ffffff",
            ":hover":{
                background:"#F78A05 !important",
                color:"#ffffff !important",
            }
        }
    },
    buttonIcon:{
        root:{
            marginRight: "5px",
            marginLeft: "5px",
            width: "22px",
            background:"#ffffff",
            selectors:{
                "i":{
                    color: '#191919',
                    fontSize:"12px",
                }
            }
        },
        rootDisabled:{
            cursor:"not-allowed !important",
            marginLeft: "5px",
            marginRight: "5px !important",
        }
    },
    totalItems:{
        display: "flex",
        alignItems: "center",
        lineHeight: "1.1",
    },
    contextMenuDropdownClass:{
        '> div .ms-Dropdown-title': {
            borderColor: '#191919'
        },
        '> div:active .ms-Dropdown-title': {
            borderColor: '#191919'
        },
        '> div:focus:after': {
            content: 'none'
        }
    },
    pageItems:{
        root: {
            width: "62px",
            height: "32px",
            borderRadius: "2px",
        }
    }
}
