import './App.css';
import StatusApp from "./Components/StatusApp";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
initializeIcons();

function App() {
  return (
    <div style={{ margin: "70px"}} className="App">
      <StatusApp />
    </div>
  );
}

export default App;
