import React, {Component} from "react";
import {IconButton,DefaultButton, TextField} from "@fluentui/react";
import { Styles } from "./pagination.styles";
import LastPageIcon from "./lastPageIcon";
import FirstPageIcon from "./firstPageIcon";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";

class Pagination extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pager: {},
            pageSize: this.props.pageSize,
            selectedPage: 1,
        };
        this.handleDebounce = debounce(this.handleDebounce.bind(this), 500);
    }

    componentWillMount() {
        if (this.props.items && this.props.items.length && !this.props.isServerSide) {
            this.setPage(this.props.initialPage);
        } else {
            this.setPage(this.props.currentPage);
        }
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.props.items !== prevProps.items && !this.props.isServerSide) {
            this.setPage(this.props.initialPage);
        }
        if (this.props.items !== prevProps.items && this.props.isServerSide ) {
            this.setPage(this.props.currentPage);
        }
        if(this.props.totalItems !== prevProps.totalItems && this.props.isServerSide) {
            this.setPage(this.props.currentPage);
        }
    }

    setPage = (page) => {
      if(this.props.isServerSide) {
            const items = this.props.items;
            const pager = this.getPager(this.props.totalItems, page, this.state.pageSize);
            this.setState({ pager: pager, selectedPage: page ? page : this.state.selectedPage });
            this.props.onChangePage(items, page);
        } else {
            const items = [...this.props.items];
            let pager = {...this.state.pager};
            const pageSize = this.state.pageSize;
            pager = this.getPager(items.length, page, pageSize);
            const pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);
            this.setState({ pager: pager, selectedPage: page ? page : this.state.selectedPage });
            this.props.onChangePage(pageOfItems, page);
        }
    }

    getPager(totalItems, currentPage, pageSize) {
        currentPage = currentPage || 1;
        pageSize = pageSize || 10;

        const totalPages = Math.ceil(totalItems / pageSize);
        let startPage, endPage;
        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage <= 3) {
                startPage = 1;
                endPage = 5;
            } else if (currentPage + 1 >= totalPages) {
                startPage = totalPages - 4;
                endPage = totalPages;
            } else {
                startPage = currentPage - 2;
                endPage = currentPage + 2;
            }
        }

        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
        const pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }

    handleDebounce = (page) => {
        const { totalPages } = this.state.pager;
        if(page > totalPages) {
            this.setPage(totalPages);
        } else {
            this.setPage(Number(page));
        }
    }

    handlePageChange = (e) => {
        const { value } = e.target;
        const regex =  /^[0-9]+$/;
        const number = regex.test(value);
        this.setState({selectedPage: Number(value)});
        if (number && Number(value) > 0 && Number(value) <= this.state.pager.totalPages) {
            this.handleDebounce(Number(value));
        } else {
            if (Number(value) >= this.state.pager.totalPages) {
                this.setState({selectedPage: Number(this.state.pager.totalPages)});
                this.handleDebounce(Number(this.state.pager.totalPages));
            }
        }
    }

    render() {
        const {pager} = this.state;
        const options = (pager.pages || []).map((page) => ({key: page, text: page}));
        if (!pager.pages || pager.pages.length <= 1) {
            return null;
        }
        return (
            <ul className="pagination" style={Styles.pagination}>
                <li >
                    <IconButton styles={Styles.buttonIcon} onClick={() => this.setPage(1)} title="First" disabled={pager.currentPage === 1} ><FirstPageIcon/></IconButton>
                </li>
                <li >
                    <IconButton styles={Styles.buttonIcon} iconProps={{iconName: "ChevronLeft"}} onClick={() => this.setPage(pager.currentPage - 1)} title="Previous" disabled={pager.currentPage === 1} />
                </li>
                {pager.pages.map((page, index) =>
                    <li key={index} style={Styles.pageCountLi}>
                        <DefaultButton styles={ pager.currentPage === page ? {root: {...Styles.active.root, ...Styles.pages.root}} : Styles.pages} text={page} onClick={() => this.setPage(page)} />
                    </li>
                )}
                <li >
                    <IconButton styles={Styles.buttonIcon} iconProps={{iconName: "ChevronRight"}} onClick={() => this.setPage(pager.currentPage + 1)} title="Next" disabled={pager.currentPage === pager.totalPages}/>
                </li>
                <li >
                    <IconButton styles={Styles.buttonIcon} onClick={() => this.setPage(pager.totalPages)} title="Last" disabled={pager.currentPage === pager.totalPages}><LastPageIcon/></IconButton>
                </li>
                <div style={Styles.totalItems}>
                    <TextField
                      styles={{root: {width: (`${this.state.selectedPage}`.length+3)*8,".ms-TextField-field":{textAlign:"center"}}}}
                      onChange={this.handlePageChange}
                      value={this.state.selectedPage}
                    />
                    <h5> &nbsp;&nbsp;{`of ${pager.totalPages} Pages`}</h5></div>
            </ul>
        );
    }
}

export default Pagination;

Pagination.propTypes = {
  pageSize: PropTypes.number,
  items: PropTypes.array,
  isServerSide: PropTypes.bool,
  initialPage: PropTypes.number,
  currentPage: PropTypes.number,
  totalItems: PropTypes.number,
  onChangePage: PropTypes.func
};


