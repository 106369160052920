import { TableStyles } from "./TableStyles.styles";
import {
    Stack
} from "@fluentui/react";

export default function StatusTable(props) {

    function status(connector) {
        if (connector.isCancelled) {
            return <span style={{ backgroundColor: "orange", borderRadius: "5px", padding: "5px 10px 5px 10px", textAlign: "center"}}>Cancelled</span>;
        } else if (connector.isCompleted){
            return <span style={{ backgroundColor: "#00c04b", borderRadius: "5px", padding: "5px", textAlign: "center"}}>Completed</span>;
        } else if (connector.isFailed ) {
            return <span style={{ backgroundColor: "red", borderRadius: "5px", padding: "5px 24px 5px 24px", textAlign: "center"}}>Failed</span>;
        } else if (connector.isProcessing) {
            return <span style={{ backgroundColor: "yellow", borderRadius: "5px", padding: "5px 8px 5px 8px", textAlign: "center"}}>Processing</span>;
        } else if (connector.isRollbackProcessing) {
            return <span style={{ backgroundColor: "orange", borderRadius: "5px", padding: "5px", textAlign: "center"}}>Rollback Processing</span>;
        } else {
            return <span style={{ backgroundColor: "white", borderRadius: "5px", padding: "5px", textAlign: "center"}}>NA</span>
        }
    }

    return<>
    <table>
        <tr>
            {props.columns.map((col, i) => <th style={TableStyles.header} key={col.key}>{col.name}</th>)}
        </tr>
        {props.data.map((tenant, indexOfTenant) => {
            return Object.keys(tenant.info).length ? Object.keys(tenant.info).map((instance, i) => {
                    let filteredConnectors = [];
                    let foundMap = "";
                    let foundRuleMatch = "";
                    let foundMLMatch = "";
                    let foundMerge = "";
                    let foundTimelines = [];
                    let foundMetrics = "";
                    let foundAudiences = [];
                    let foundPredictions = [];
                    
                    tenant.info[instance]?.length && tenant.info[instance].forEach(instanceDetails => {
                        if (instanceDetails.groupName === "Dataflow") {
                            filteredConnectors.push(instanceDetails)
                        }
                        if (instanceDetails.groupName === "System Process") {
                            if (instanceDetails.displayName === "Map") {
                                foundMap = instanceDetails;
                            }
                            if (instanceDetails.displayName === "RuleMatch") {
                                foundRuleMatch = instanceDetails;
                            }
                            if (instanceDetails.displayName === "MLMatch") {
                                foundMLMatch = instanceDetails;
                            }
                            if (instanceDetails.displayName === "Merge") {
                                foundMerge = instanceDetails;
                            }
                        }
                        if (instanceDetails.groupName === "Timelines") {
                            foundTimelines.push(instanceDetails)
                        }
                        if (instanceDetails.groupName === "Metrics") {
                            foundMetrics = instanceDetails
                        }
                        if (instanceDetails.groupName === "Audiences") {
                            foundAudiences.push(instanceDetails)
                        }
                        if (instanceDetails.groupName === "Predictions") {
                            foundPredictions.push(instanceDetails)
                        }
                    })

                    return <tr>
                        {(i === 0) && <td rowSpan={Object.keys(tenant.info).length}><tr>{tenant.tenant}</tr></td>}
                        <td>{instance}</td>
                        <td>
                            {filteredConnectors.length ? filteredConnectors.map(connector => 
                                    <Stack horizontal style={{ justifyContent: "space-between"}}>
                                        <td style={{ border: "none", paddingTop: "15px"}}>{connector.name}</td>
                                        <td style={{ border: "none", padding: "2px" }}>
                                            <td style={{ border: "none"}}> {status(connector)} </td>
                                            <td style={{ border: "none"}}> {connector.lastUpdateDate.split(",")[0]} </td>
                                        </td>
                                    </Stack>
                                ) : <tr><td style={{ border: "none"}}>NA</td></tr>}
                        </td>
                        <td>
                            {foundMap ? <tr><div>{status(foundMap)}</div><div style={{marginTop: "10px", textAlign: "center"}}>{foundMap.lastUpdateDate.split(",")[0]}</div></tr> : <tr>NA</tr>}
                        </td>
                        <td>
                            {foundRuleMatch ? <tr><div>{status(foundRuleMatch)}</div><div style={{marginTop: "10px", textAlign: "center"}}>{foundRuleMatch.lastUpdateDate.split(",")[0]}</div></tr> : <tr>NA</tr>}
                        </td>
                        <td>
                            {foundMLMatch ? <tr><div>{status(foundMLMatch)}</div><div style={{marginTop: "10px", textAlign: "center"}}>{foundMLMatch.lastUpdateDate.split(",")[0]}</div></tr> : <tr>NA</tr>}
                        </td>
                        <td>
                            {foundMerge ? <tr><div>{status(foundMerge)}</div><div style={{marginTop: "10px", textAlign: "center"}}>{foundMerge.lastUpdateDate.split(",")[0]}</div></tr> : <tr>NA</tr>}
                        </td>
                        <td>
                            {foundTimelines.length ? foundTimelines.map(connector => 
                                <Stack horizontal style={{ justifyContent: "space-between"}}>
                                    <td style={{ border: "none", paddingTop: "15px"}}>{connector.name}</td>
                                    <td style={{ border: "none", padding: "2px" }}>
                                        <td style={{ border: "none"}}> {status(connector)} </td>
                                        <td style={{ border: "none"}}> {connector.lastUpdateDate.split(",")[0]} </td>
                                    </td>
                                </Stack>
                                ) : <tr><td style={{ border: "none"}}>NA</td></tr>}
                        </td>
                        <td>
                            {foundMetrics ? <tr><div>{status(foundMetrics)}</div><div style={{marginTop: "10px", textAlign: "center"}}>{foundMetrics.lastUpdateDate.split(",")[0]}</div></tr> : <tr><td style={{ border: "none"}}>NA</td></tr>}
                        </td>
                        <td>
                            {foundAudiences.length ? foundAudiences.map(connector => 
                                <Stack horizontal style={{ justifyContent: "space-between"}}>
                                    <td style={{ border: "none", paddingTop: "15px"}}>{connector.name}</td>
                                    <td style={{ border: "none", padding: "2px" }}>
                                        <td style={{ border: "none"}}> {status(connector)} </td>
                                        <td style={{ border: "none"}}> {connector.lastUpdateDate.split(",")[0]} </td>
                                    </td>
                                </Stack>
                                ) : <tr><td style={{ border: "none"}}>NA</td></tr>}
                        </td>
                        <td>
                            {foundPredictions.length ? foundPredictions.map(connector => 
                                <Stack horizontal style={{ justifyContent: "space-between"}}>
                                    <td style={{ border: "none", paddingTop: "15px"}}>{connector.name}</td>
                                    <td style={{ border: "none", padding: "2px" }}>
                                        <td style={{ border: "none"}}> {status(connector)} </td>
                                        <td style={{ border: "none"}}> {connector.lastUpdateDate.split(",")[0]} </td>
                                    </td>
                                </Stack>
                                ) : <tr><td style={{ border: "none"}}>NA</td></tr>}
                        </td>
                    </tr>}) 
                    : 
                    <tr><td colSpan={15} style={{ textAlign: "center"}}>There are no Instances in "{tenant.tenant}" Tenant</td></tr>}
              
            )}
        </table>
        </>
    }