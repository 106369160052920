export const TableStyles = {
    header: {
        backgroundColor: "#afc9fa"
    },
    tenantAndInstances: {
        backgroundColor: "antiquewhite"
    },
    connectors: {
        backgroundColor: "#f4f5f0"
    },
    stitch: {
        backgroundColor: "#76f593"
    },
    timelines: {
        backgroundColor: "#d5f5e0"
    },
    metrics: {
        backgroundColor: "#8cdea2"
    },
    audiences: {
        backgroundColor: "#effaac"
    },
    predictions: {
        backgroundColor: "#91fa9d"
    },
}

export const stackItemStyles = {
    root: {
      alignItems: 'center',
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      marginBottom: "40px"
    },
};

export const dropdownStyles = {
    dropdown: { width: 250 },
};