import { useEffect, useState } from "react"
import {
    Spinner,
    SpinnerSize,
    Dropdown,
    Stack,
    Text
} from "@fluentui/react";
import axios from "axios";
import StatusTable from "./StatusTable"
import Pagination from "./FluentTable/pagination"
import { stackItemStyles, dropdownStyles } from "./TableStyles.styles"

export default function StatusApp() {
    const [data, setData] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setpageSize] = useState(2);
    const [isLoading, setIsLoading] = useState(true)
    const [totalPages, setTotalPages] = useState(10);
    const [selectedENV, setSelectedENV] = useState("https://gwdev.skypointcloud.com/internal");
    const options = [
      {key: 1, text: "1"},
      {key: 2, text: "2"},
      {key: 3, text: "3"},
      {key: 4, text: "4"},
      {key: 5, text: "5"},
      {key: 6, text: "6"},
      {key: 7, text: "7"},
      {key: 8, text: "8"},
      {key: 9, text: "9"},
      {key: 10, text: "10"}
    ];
    const envOptions = [
      {key: "https://gwdev.skypointcloud.com/internal", text: "Dev"},
      {key: "https://gwqa.skypointcloud.com/internal", text: "QA"},
      {key: "https://gw.skypointcloud.com/internal", text: "Prod"},
    ]

    useEffect(() => {
        fetchAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNo, pageSize, selectedENV])

    async function fetchAPI() {
        setIsLoading(true);
        try {
          const platformData = await axios.get(`${selectedENV}/api/tenants/platformProcessesAllTenants?pageNumber=${pageNo}&pageSize=${pageSize}&orderBy='BusinessName'`);
          let modifiedData = [];
          Object.keys(platformData.data.data).forEach(tenant => {
              let ten = {
                  tenant: [tenant][0],
                  info: platformData.data.data[tenant]
              }
              modifiedData.push(ten)
          })
          setTotalPages(platformData.data.totalRecords)
          setData(modifiedData)
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          console.log("Error", error)
        }
    }

    const columns = [
        {
            key: "tenant",
            name: "Tenant",
          },
          {
            key: "Instances",
            name: "Instances",
          },
          {
            key: "Connectors",
            name: "Connectors",
        },
          {
            key: "Map",
            name: "Map",
          },
          {
            key: "Rule Match",
            name: "Rule Match",
          },
          {
            key: "ML Match",
            name: "ML Match",
          },
          {
            key: "Merge",
            name: "Merge",
          },
          {
            key: "Timeline",
            name: "Timeline",
          },
          {
            key: "Metrics",
            name: "Metrics",
          },
          {
            key: "Audience",
            name: "Audience",
          },
          {
            key: "Predictions",
            name: "Predictions",
          },
    ]

    function onChangePage(a, b, c) {
        setPageNo(b)
    }

    const stackTokens = {
      childrenGap: 60,
      padding: 10,
    };

    return isLoading ? <Spinner label="Loading..." size={SpinnerSize.large}/> :
        <>
            <Stack horizontal styles={stackItemStyles} tokens={stackTokens}>
               <Dropdown
                  placeholder="Select number of Tenants to Load per page"
                  label="Number of Tenants per page"
                  options={options}
                  defaultSelectedKey={pageSize}
                  onChange={(e, item) => setpageSize(item.key)}
                  styles={dropdownStyles}
                />
                <Text variant="xxLarge">Platform Health</Text>
                <Dropdown
                  placeholder="Select the Environmen"
                  label="Select the Environment"
                  options={envOptions}
                  defaultSelectedKey={selectedENV}
                  onChange={(e, item) => setSelectedENV(item.key)}
                  styles={dropdownStyles}
                />
            </Stack>
            <StatusTable data={data} columns={columns}/>

            <Pagination
                items={data}
                pageSize={pageSize}
                initialPage={1}
                onChangePage={onChangePage}
                totalItems={totalPages}
                currentPage={pageNo}
                isServerSide={true}
            />
        </>
}