import React from "react";

const LastPageIcon = () => {
    return <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.85547 11.8535L3.14648 11.1445L8.29102 6L3.14648 0.855469L3.85547 0.146484L9.70898 6L3.85547 11.8535Z" fill="#191919"/>
        <line x1="12.5" y1="2.18557e-08" x2="12.5" y2="12" stroke="black"/>
    </svg>

}

export default LastPageIcon